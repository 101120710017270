import { initiateGam } from '@client/adManager/initiateGam';
import { initiateAdn } from '@client/adnuntius/initiateAdn';
import { triggerFinnAfsRequest } from '@client/adsense/finn-afs';
import { setupEvents } from '@client/core//utils/events';
import { defineAdvtComponent } from '@client/core/components/webComponents';
import { sendErrorMessageMetricToServer } from '@client/core/services/metrics';
import { getConsentOrSubscribe } from '@client/core/utils/consent';
import { initiateHandleStickySkyscrapers } from '@client/core/utils/dom/handleAdsOverlap/handleStickySkyscrapers';
import { persistPPID } from '@client/core/utils/pulse/pulse';
import { debugLog, InitialState } from '@schibsted-nmp/advertising-shared';

import { loadGlimrIfPermission } from './core/utils/glimr';

import './dev/AdvtDevTool';

/**
 * Initiates ad rendering, setup page options and handles each placement.
 * @param initialState The initial state for setting up ads.
 */
export async function initiateAdvt(initialState: InitialState): Promise<void> {
  try {
    debugLog('Inside initiateAdvt()');

    const { config } = initialState;

    await Promise.all([
      await persistPPID(),
      await loadGlimrIfPermission(config.brand)
    ]);

    setupEvents();

    if (config.deviceType === 'desktop') {
      initiateHandleStickySkyscrapers();
    }

    if (config.brand?.toLowerCase() === 'finn') {
      triggerFinnAfsRequest();
    }

    defineAdvtComponent();

    if (config?.adServer.gam) {
      getConsentOrSubscribe(async (permissionValue) => {
        if (permissionValue === '1' || config.brand !== 'dba') {
          initiateGam(config.adServer.gam);
        }
      });
    }

    if (config?.adServer.adn) {
      getConsentOrSubscribe(async (permissionValue) => {
        if (permissionValue === '0') {
          initiateAdn(config.adServer.adn);
        }
      });
    }
    debugLog('Advt initiated');
  } catch (e) {
    console.error('Failed to initiate ads', e);
    sendErrorMessageMetricToServer(`Failed to initiate ads ${e.message}`).then(
      () => {
        debugLog('Sent error message metric to server');
      }
    );
  }
}
