import pulse from '@finn-no/pulse-sdk';
import {
  debugLog,
  PPIDObject,
  PPIDResponse
} from '@schibsted-nmp/advertising-shared';
import { $ppidAtom, emptyPpId } from '@client/core/atoms/config';
import localStorage from '@client/core/utils/localStorage';

export type PulseTracker = any;

export function getTracker(): Promise<PulseTracker> {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const errMsg = 'Timeout from pulse.getTracker()';
      console.error(`[advertising-podlet] ${errMsg}`);
      reject(new Error(errMsg));
    }, 2000);

    pulse.getTracker((tracker) => {
      if (tracker) {
        clearTimeout(timeout);
        resolve(tracker);
      } else {
        clearTimeout(timeout);
        const errMsg = 'No tracker found from pulse.getTracker()';
        console.error(`[advertising-podlet] ${errMsg}`);
        reject(new Error(errMsg));
      }
    });
  });
}

export function getPulseData(
  pulseFunction: (tracker: PulseTracker) => Promise<string | undefined>
): (tracker: PulseTracker) => Promise<string | undefined> {
  return (tracker: PulseTracker) =>
    new Promise((resolve, reject) => {
      pulseFunction(tracker).then(resolve).catch(reject);
    });
}

export const PPID_STORAGE_NAME = 'advertising-podlet.ppid';

export async function persistPPID(): Promise<PPIDResponse> {
  try {
    const tracker = await getTracker();

    const res = await getAdvertisingIdentifiers(tracker);

    debugLog(`PPID response: ${JSON.stringify(res, null, 2)}`);
    const ppidData = {
      ...emptyPpId,
      ...res
    } as PPIDResponse;

    if (ppidData.delta) {
      $ppidAtom.set(ppidData);
    }

    localStorage.setItem(PPID_STORAGE_NAME, ppidData);

    return ppidData;
    // Set the object on window for use in banners.tsx and other potential places
  } catch (err) {
    return $ppidAtom.get();
  }
}

export function getAdvertisingIdentifiers(tracker: PulseTracker): Promise<{
  delta: PPIDObject;
}> {
  return new Promise((resolve, reject) => {
    // gets delta and xandr automatically
    tracker.getAdvertisingIdentifiers().then(resolve).catch(reject);
  });
}

export const getLoggedInPPID = getPulseData((tracker) =>
  tracker.getXandrPPID1()
);
export const getNotLoggedInPPID = getPulseData((tracker) =>
  tracker.getXandrPPID2()
);
export const getEnvironmentId = getPulseData((tracker) =>
  tracker.getEnvironmentId()
);
