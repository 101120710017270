import { updateMetricByKey } from '@client/core/atoms/metrics';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements';
import {
  AdType,
  debugLog,
  GamEventListenerEventName,
  PlacementId,
  PlacementStatusesEnum,
  Slot
} from '@schibsted-nmp/advertising-shared';

import {
  eventIsNativeCpmAd,
  sizeIsAdnami,
  sizeIsFullScreenAd,
  sizeIsNativeAd
} from '../util';

export const setupGamEventListeners = (slot: Slot) => {
  const placementId = slot.getSlotElementId() as PlacementId;

  const events: GamEventListenerEventName[] = [
    // Fired when an ad has been requested for the ad slot.
    'slotRequested',
    // Fired when creative code has been injected into an ad slot.
    'slotRenderEnded',
    // Fired when an impression becomes viewable.
    'impressionViewable',
    // Fired when the on-screen percentage of the ad slot changes.
    'slotVisibilityChanged',
    // Fired when a creative iframe fires its onload event.
    'slotOnload'
  ];
  events.forEach((eventType) => {
    window.googletag.cmd.push(() => {
      window.googletag.pubads().addEventListener(eventType, (event) => {
        const eventSlot = event.slot;
        const eventElemId = eventSlot.getSlotElementId();

        if (eventElemId !== placementId) {
          // Skip events that are not related to the current slot
          return;
        }

        if (eventType !== 'slotVisibilityChanged') {
          debugLog(`PlacementId: ${placementId}: ${eventType} event:`, event);
        }

        if (eventType === 'slotRequested') {
          debugLog(
            `Google ad requested for placement: ${placementId} with slot`,
            eventSlot
          );
          updatePlacementKeyValueById(placementId, 'creativeType', 'banner');
          updatePlacementKeyValueById(placementId, 'status', 'requested');

          updateMetricByKey(placementId, PlacementStatusesEnum.AdRequested);
        }

        if (eventType === 'slotRenderEnded') {
          if (event?.isEmpty) {
            debugLog(
              `Google ad did not fill for placement: ${placementId} with slot`,
              eventSlot
            );
            updateMetricByKey(placementId, PlacementStatusesEnum.AdNoFill);
            updatePlacementKeyValueById(placementId, 'status', 'error');
          } else {
            let creativeType: AdType = 'banner';

            if (sizeIsAdnami(event.size)) {
              creativeType = 'adnami';
            } else if (sizeIsFullScreenAd(event.size)) {
              creativeType = 'takeover';
            } else if (eventIsNativeCpmAd(event)) {
              creativeType = 'sponsored-content';
            } else if (sizeIsNativeAd(event.size)) {
              creativeType = 'native';
            }

            debugLog(`Creative type from size ${event.size}: ${creativeType}`);
            updatePlacementKeyValueById(
              placementId,
              'creativeType',
              creativeType
            );
            updatePlacementKeyValueById(placementId, 'status', 'loaded');
            updateMetricByKey(placementId, PlacementStatusesEnum.AdLoaded);
          }
        }

        if (eventType === 'impressionViewable') {
          updateMetricByKey(placementId, PlacementStatusesEnum.Viewed);
        }
      });
    });
  });
};
