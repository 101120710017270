import { RefObject, useCallback, useEffect, useState } from 'react';

import { $config, $initialState } from '@client/core/atoms/config';
import { updateMetricByKey } from '@client/core/atoms/metrics';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements';
import { getAllFeatureStatuses } from '@client/core/atoms/unleashFeatures';
import { useInScreen } from '@client/core/hooks';
import {
  forceBatchRequestPlacements,
  getInViewSettingsByBrand
} from '@client/core/utils/getInViewSettingsByPlacementId';
import {
  ClientAdPlacement,
  debugLog,
  GamConfig,
  GamPlacement,
  getSizesByMediaType,
  PlacementId,
  PlacementStatusesEnum,
  Slot
} from '@schibsted-nmp/advertising-shared';

import { getDebouncedLoadAdFunction } from '../batch';
import { setTargetingOnSlotOrGlobal } from '../targeting';
import { applySizeMapping } from '../utils/sizeMapping';
import { GamAdUnitProps } from './GamAdUnit';
import { setupGamEventListeners } from './setupGamEventListeners';
import { ensurePathStartsWithSlash } from './utils';

export function useInitiateGamUnit(
  props: GamAdUnitProps & { ref: RefObject<HTMLDivElement> }
) {
  const [placementIsInView, setPlacementIsInView] = useState(false);

  const { adServer } = $config.get();
  const gamConfig = adServer.gam as GamConfig;
  const { placement } = props;
  const { placementId, status } = placement;

  const { isIntersecting } = useShouldLoadAd({
    placementId,
    ref: props.ref
  });

  const [slotState, setSlotState] = useState<Slot | null>(() =>
    getGamSlotOrNull(placementId)
  );

  function getGamSlotOrNull(pId: PlacementId): Slot | null {
    if (!window.googletag) {
      console.error('Google Publisher Tag not initialized');
      return null;
    }
    if (!window.googletag.pubads) {
      console.error('Google Publisher Tag PubAdsService not initialized');
      return null;
    }
    const slot = window.googletag
      .pubads()
      .getSlots()
      .find((slot: any) => slot.getSlotElementId() === pId);
    return slot || null;
  }
  /**
   * This loads the ads in a batch
   */
  const sendToRequestBatchAds = useCallback((slot: Slot | null) => {
    if (slot) {
      getDebouncedLoadAdFunction()(slot);
    }
  }, []);

  // Some placements need to wait for a delay before they are in view, because
  // of pagination trouble etc
  useEffect(() => {
    if (status === 'refresh') {
      const placementsToWaitFor = [PlacementId.Bottom1];

      // As soon as the ad is not intersecting anymore, we will stage it to be ready to load (pending)
      if (placementsToWaitFor.includes(placementId)) {
        if (!isIntersecting) {
          updatePlacementKeyValueById(placementId, 'status', 'pending');
          setPlacementIsInView(isIntersecting);
        }
      } else {
        updatePlacementKeyValueById(placementId, 'status', 'pending');
        setPlacementIsInView(isIntersecting);
      }
    } else {
      setPlacementIsInView(isIntersecting);
    }
  }, [isIntersecting, status, placementId]);

  useEffect(() => {
    // forceBatchRequest is a special case where we want to load the ads in a batch, such as the horseshoe placements (left,right,top,wallpaper)
    // If that happens, completely ignore inView and just send them straight to request
    const isPendingInViewOrForceBatch =
      (placementIsInView && status === 'pending') ||
      status === 'forceBatchRequest';
    if (slotState && isPendingInViewOrForceBatch) {
      updatePlacementKeyValueById(placementId, 'status', 'request');
      sendToRequestBatchAds(slotState);
    }
  }, [slotState, placementIsInView, placementId, status]);

  const getSlot = (placement: ClientAdPlacement<GamPlacement>) => {
    const {
      targeting = [],
      path,
      sizeMappings,
      mediaTypes
    } = placement.adServer?.config || {};

    const sizes = getSizesByMediaType(mediaTypes) as number[][];

    const placementConfigPath = ensurePathStartsWithSlash(path);

    const slot = window.googletag
      .defineSlot(placementConfigPath, sizes, placement.placementId)
      .addService(window.googletag.pubads());

    // Apply size mapping if available
    if (sizeMappings && sizeMappings.length > 0) {
      applySizeMapping(slot, sizeMappings, window.googletag);
    }

    if ($initialState.get().env === 'local') {
      targeting.push({ key: 'test', value: 'true' });
    }

    const { enableGamTestCampaign } = getAllFeatureStatuses();

    if (enableGamTestCampaign) {
      targeting.push({ key: 'gamTestCampaign', value: 'true' });
    }

    if (targeting?.length > 0) {
      setTargetingOnSlotOrGlobal({
        slot,
        targeting,
        global: false
      });
    }
    return slot;
  };
  /**
   * Set up the ad
   */
  useEffect(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    if (placementId && !slotState) {
      // TODO: Implement consent handling with getConsentStatusOrSubscribe
      window.googletag.cmd.push(() => {
        // Check if the slot is already defined and destroy it if necessary
        if (getGamSlotOrNull(placementId)) {
          // slot already exists
          return;
        }
        debugLog('Defining Slot for placement: ', placementId);

        const slot = getSlot(placement);

        if (slot) {
          setSlotState(slot);
          updateMetricByKey(placementId, PlacementStatusesEnum.SlotCreated);
          debugLog('Gam successfully set-up for placement:', placementId);

          // Add event listeners for ad events
          setupGamEventListeners(slot);
        }
      });
    }
  }, [placement, gamConfig, placementId, getSlot, slotState]);

  return { placement, isIntersecting };
}

function useShouldLoadAd({
  placementId,
  ref
}: {
  placementId: PlacementId;
  ref: RefObject<HTMLDivElement>;
}) {
  const inViewSettings = forceBatchRequestPlacements.includes(placementId)
    ? null
    : getInViewSettingsByBrand(placementId);

  const { isIntersecting } = useInScreen({
    ref,
    ...(inViewSettings || {})
  });

  return {
    inViewSettings,
    isIntersecting: isIntersecting || !inViewSettings
  };
}
