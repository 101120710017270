import { removeWallpaperDiv } from '@client/adManager/AdUnit/utils';
import { triggerFinnAfsRequest } from '@client/adsense/finn-afs';
import {
  getPlacementList,
  updatePlacementStatuses
} from '@client/core/atoms/placements';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import fetcher from '@client/core/utils/api/fetcher';
import { getPermissionValueIfExists } from '@client/core/utils/consent';
import { getInitialState } from '@client/core/utils/dom/getInitialState';
import {
  AdConfig,
  debugLog,
  filterAfsPlacements,
  SearchConfigBody,
  SearchKey,
  SearchParams,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { setAdnTargetingAtom } from '@client/core/atoms/adnTargeting';

import { $afsAtom } from '../atoms/afs';
import { $config, $searchFilters } from '../atoms/config';
import { setGamTargetingAtom } from '../atoms/gamTargeting';
import { addLifecycleEvent } from '../atoms/metrics';
import { forceBatchRequestPlacements } from './getInViewSettingsByPlacementId';

export type FilterUpdatePayloadType = {
  searchKey: SearchKey;
  searchParams: SearchParams;
};

function shouldUpdateKeywords(): boolean {
  // If the search params have changed, we need to update the keywords.
  // Here we can do more specific checks such as update on pagination but do not on sort.

  const currentSearchParams = $searchFilters.get().current || {};
  if (Object.entries(currentSearchParams).length === 0) return false;

  return $searchFilters.get().previous !== currentSearchParams;
}

export const refreshKeywords = async (event) => {
  try {
    const payload = event.payload as FilterUpdatePayloadType;
    debugLog(`Received search filter update:`, payload);

    $searchFilters.set({
      previous: $searchFilters.get().previous,
      current: payload.searchParams
    });

    if (shouldUpdateKeywords()) {
      const { brand, deviceType, vertical, subvertical } = $config.get();
      const consent = getPermissionValueIfExists();
      const body = {
        logSessionId: getInitialState()?.logSessionId || '',
        searchKey: payload.searchKey,
        searchParams: payload.searchParams,
        deviceType,
        consent,
        vertical,
        brand,
        subvertical
      } as SearchConfigBody;

      // Determine if the advertising config APIs are disabled
      const disableAdvertisingConfigApi = isFeatureEnabled(
        UNLEASH_FEATURE_NAME.disableAdvertisingConfigApi
      );

      // On page refresh, we need to remove the wallpaper div
      removeWallpaperDiv();

      // Split placements into two lists based on forceBatchRequestPlacements
      const allPlacements = getPlacementList();
      const [placementsToForceBatch, placementsToRefresh] =
        allPlacements.reduce(
          (acc, placement) => {
            if (forceBatchRequestPlacements.includes(placement.placementId)) {
              acc[0].push(placement);
            } else {
              acc[1].push(placement);
            }
            return acc;
          },
          [[], []] as [typeof allPlacements, typeof allPlacements]
        );

      // Update statuses for both groups
      updatePlacementStatuses(placementsToRefresh, 'refresh');
      updatePlacementStatuses(placementsToForceBatch, 'forceBatchRequest');

      // Create promises conditionally
      const searchFilterConfig = await (disableAdvertisingConfigApi
        ? Promise.resolve({
            data: null,
            error: ''
          })
        : fetcher<{
            data: AdConfig;
            error: string;
          }>('/api/update/targeting', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          }));

      addLifecycleEvent('Refresh targeting config after filter update');

      const filterData = searchFilterConfig?.data as AdConfig;

      $searchFilters.set({
        previous: payload.searchParams,
        current: $searchFilters.get().current
      });

      if (filterData?.adServer?.gam) {
        setGamTargetingAtom(filterData.adServer.gam.targeting);
      }
      if (filterData?.adServer?.adn) {
        setAdnTargetingAtom(filterData.adServer.adn.kv);
      }

      if (filterData?.adServer?.afs) {
        const placements = filterAfsPlacements(filterData?.placements);
        const { afs } = filterData.adServer;
        debugLog(`Sending AFS config to AFS ad vendor`, {
          afs,
          placements
        });
        if (placements.length > 0) {
          $afsAtom.set({
            config: afs,
            placements
          });
        }
      }

      if (brand === 'finn') {
        triggerFinnAfsRequest();
      }
    }
  } catch (error) {
    console.error(`Error in search-config API fetch: ${error}`);
  }
  return true;
};
