import { RefObject, useCallback, useEffect, useState } from 'react';

import { $config } from '@client/core/atoms/config';
import { updateMetricByKey } from '@client/core/atoms/metrics';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements';
import { useInScreen } from '@client/core/hooks';
import { getInViewSettingsByBrand } from '@client/core/utils/getInViewSettingsByPlacementId';
import {
  AdnAdUnit,
  AdnConfig,
  AdnPlacement,
  ClientAdPlacement,
  debugLog,
  PlacementId,
  PlacementStatusesEnum
} from '@schibsted-nmp/advertising-shared';
import { AdnAdUnitProps } from '@client/adnuntius/AdUnit/AdnAdUnit';
import { getAllFeatureStatuses } from '@client/core/atoms/unleashFeatures';
import { $adnTargetingAtom } from '@client/core/atoms/adnTargeting';

import { getDebouncedLoadAdFunction } from '../batch';

export function useInitiateAdnUnit(
  props: AdnAdUnitProps & { ref: RefObject<HTMLDivElement> }
) {
  const [placementIsInView, setPlacementIsInView] = useState(false);

  const { adServer } = $config.get();
  const adnConfig = adServer.adn as AdnConfig;
  const { placement } = props;
  const { placementId, status } = placement;

  const { isIntersecting: isInView } = useShouldLoadAd({
    placementId,
    ref: props.ref
  });
  const [slotState, setSlotState] = useState<AdnAdUnit | null>(null);

  /**
   * This loads the ads in a batch
   */
  const sendToRequestBatchAds = useCallback((adUnit: AdnAdUnit | null) => {
    if (adUnit) {
      getDebouncedLoadAdFunction()(adUnit);
    }
  }, []);

  // Some placements need to wait for a delay before they are in view, because
  // of pagination trouble etc
  useEffect(() => {
    if (status === 'refresh') {
      // Wait for ads that are currently in view
      if (!isInView) {
        updatePlacementKeyValueById(placementId, 'status', 'pending');
        setPlacementIsInView(isInView);
      }
    } else {
      setPlacementIsInView(isInView);
    }
  }, [isInView, status, placementId]);

  useEffect(() => {
    // forceBatchRequest is a special case where we want to load the ads in a batch, such as the horseshoe placements (left,right,top,wallpaper)
    // If that happens, completely ignore inView and just send them straight to request
    const isPendingInViewOrForceBatch =
      (placementIsInView && status === 'pending') ||
      status === 'forceBatchRequest';
    if (slotState && isPendingInViewOrForceBatch) {
      updatePlacementKeyValueById(placementId, 'status', 'request');
      sendToRequestBatchAds(slotState);
    }
  }, [slotState, placementIsInView, placementId, status]);

  const getAdUnit = (placement: ClientAdPlacement<AdnPlacement>) => {
    const { targetId, auId } = placement.adServer?.config || {};
    const kv = $adnTargetingAtom.get();

    const { enableAdnTestCampaign } = getAllFeatureStatuses();
    if (enableAdnTestCampaign) {
      kv.push({ adnTestCampaign: ['true'] });
    }
    return {
      auId,
      targetId,
      kv
    } as AdnAdUnit;
  };
  /**
   * Set up the ad
   */
  useEffect(() => {
    if (placementId && !slotState) {
      debugLog('Defining Slot for placement: ', placementId);

      const adUnit = getAdUnit(placement);

      if (adUnit) {
        setSlotState(adUnit);
        updateMetricByKey(placementId, PlacementStatusesEnum.SlotCreated);
        debugLog('Adn successfully set-up for placement:', placementId);

        // Add event listeners for ad events
      }
    }
  }, [placement, adnConfig, placementId, getAdUnit, slotState]);

  return { placement };
}

function useShouldLoadAd({
  placementId,
  ref
}: {
  placementId: PlacementId;
  ref: RefObject<HTMLDivElement>;
}) {
  const inViewSettings = getInViewSettingsByBrand(placementId);

  const { isIntersecting } = useInScreen({
    ref,
    ...(inViewSettings || {})
  });

  return {
    inViewSettings,
    isIntersecting: isIntersecting || !inViewSettings
  };
}
