import React from 'react';

import { $config } from '@client/core/atoms/config';
import { ConsentStatus } from '@schibsted-nmp/advertising-shared';

// Add this function to get user ID from meta tag
export const getUserId = () => {
  if (typeof window === 'undefined') return '';
  const metaTag = document.querySelector('meta[name="nmp:tracking:login-id"]');
  return metaTag?.getAttribute('content') || 'Not signed in';
};

export const ShortStatus = () => {
  const config = $config.get();

  const configItems = [
    {
      label: 'pageType',
      value:
        config?.pageType === 'result'
          ? `${config?.pageType} ${config?.searchKey?.replace('SEARCH_ID_', '')}`
          : config?.pageType
    },
    { label: 'deviceType', value: config?.deviceType },
    { label: 'brand', value: config?.brand },
    { label: 'vertical', value: `${config?.vertical} ${config?.subvertical}` },
    {
      label: 'user',
      value: `${getUserId()}, ${config?.consent === ConsentStatus.Accepted ? 'Consented' : 'No consent'}`
    },
    {
      label: 'Adservers',
      value: Object.keys(config?.adServer || {})
        .map((key) => key.toUpperCase())
        .join(', ')
    }
  ];

  return (
    <div className="grid grid-cols-3 gap-24 p-24">
      {configItems.map((item, index) => (
        <div
          key={index}
          className="flex flex-col gap-4"
          style={{ textAlign: 'center' }}
        >
          <small className="text-xs s-text-subtle">{item.label}</small>
          <b className="text-s">{item.value}</b>
        </div>
      ))}
    </div>
  );
};
