import React from 'react';

import { createRoot } from 'react-dom/client';

import { updateMetricByKey } from '@client/core/atoms/metrics';
import { $placementsMap } from '@client/core/atoms/placements';
import {
  AdnPlacement,
  PlacementId,
  PlacementStatusesEnum
} from '@schibsted-nmp/advertising-shared';
import { ClientAdPlacement } from '@schibsted-nmp/advertising-shared/src/types/client';
import AdnAdUnit from '@client/adnuntius/AdUnit/AdnAdUnit';

export function renderAdnAd(props: {
  placementId: PlacementId;
  containerId: string;
}) {
  const element = document.getElementById(props.containerId);
  const placementElement = element && createRoot(element);

  if (placementElement) {
    const placements = $placementsMap.get();
    if (Object.keys(placements).length === 0) {
      console.error(`No placements found`);
      updateMetricByKey(props.placementId, PlacementStatusesEnum.NotInConfig);
      return;
    }
    const placement = placements[
      props.placementId
    ] as ClientAdPlacement<AdnPlacement>;
    if (!placement) {
      console.error(`PlacementId: ${props.placementId} not found`);
      return;
    }

    placementElement.render(
      <AdnAdUnit containerId={props.containerId} placement={placement} />
    );
  }
}
