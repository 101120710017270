import { Brand } from '@schibsted-nmp/advertising-shared';

const brandScripts: Omit<
  Record<Brand, string>,
  'finn' | 'blocket' | 'bilbasen' | 'oikotie' | 'rakentaja'
> = {
  tori: 'https://functions.adnami.io/api/macro/adsm.macro.beta.tori.fi.js',
  dba: 'https://macro.adnami.io/macro/spec/adsm.macro.d1be15b8-7ada-45b8-81b7-e2dc40458ca4.js'
};

export const getAdNamiScript = (brand: Brand) => {
  const script = brandScripts[brand];

  if (!script) {
    return null;
  }

  return {
    name: 'adnami',
    src: script
  };
};
