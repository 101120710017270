import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import { getAdNamiScript } from '@client/core/utils/getAdnamiScript';
import {
  debugLog,
  InitialState,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

import { getRelevantYieldScript } from './core/relevantDigital/config';

const loadScript = ({
  name,
  src,
  defer,
  crossOrigin
}: {
  name: string;
  src: string;
  defer?: boolean;
  crossOrigin?: string;
}) =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;

    if (defer) script.defer = defer;
    if (crossOrigin) script.crossOrigin = crossOrigin;
    script.onload = () => {
      debugLog(`${name} loaded from advertising-podlet`);
      resolve(true);
    };
    document.head.appendChild(script);
  });

interface Script {
  name: string;
  src: string;
  defer?: boolean;
  crossorigin?: string;
  async?: boolean;
}

export async function loadAllScripts(
  brand,
  env,
  deviceType,
  initialState: InitialState
) {
  const enableGamPrebid = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamPrebid
  );
  const enableBrandMetricsScript = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableBrandMetricsScript
  );

  const scriptsToLoad = [
    {
      name: 'cogwheel',
      src: 'https://cogwheel.inventory.schibsted.io/prod/latest/gdpr-bundle.js',
      defer: true
    },
    getAdNamiScript(brand)
  ] as Script[];

  const relevantYieldScript = getRelevantYieldScript(brand, deviceType);
  if (enableGamPrebid && relevantYieldScript?.src) {
    scriptsToLoad.push(relevantYieldScript);
  }

  // Load stuff that makes pulse.getTracker work
  if (env === 'local') {
    scriptsToLoad.push({
      name: 'pulse',
      src: 'https://sdk-cdn.pulse.m10s.io/versioned/3.0/pulse-modern.min.js'
    });
    scriptsToLoad.push({
      name: 'pulse-init',
      src: 'https://assets.finn.no/pkg/@finn-no/finn-pulse-init/v1/finn-pulse-init.min.js'
    });
  }
  if (enableBrandMetricsScript) {
    scriptsToLoad.push({
      name: 'brand-metrics',
      src: BRAND_METRICS_URLS[brand]
    });
  }
  if (initialState.config.adServer.gam) {
    scriptsToLoad.push({
      name: 'gam',
      src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
      defer: true
    });
  }
  if (initialState.config.adServer.adn) {
    scriptsToLoad.push({
      name: 'adn',
      src: 'https://cdn.adnuntius.com/adn.js',
      defer: true
    });
  }

  const loadAllScriptsPromises = scriptsToLoad.map(
    (script) => script?.src && loadScript(script)
  );
  await Promise.all(loadAllScriptsPromises);
}

const BRAND_METRICS_URLS = {
  finn: 'https://cdn.brandmetrics.com/tag/1978245a-275b-4980-8918-4f1dc33badbf/finn.no.js',
  tori: 'https://cdn.brandmetrics.com/tag/5b1cffc6-4c67-4602-82d7-a77c0645fca6/tori.no.js',
  blocket:
    'https://cdn.brandmetrics.com/tag/6de9037c-8539-4e57-9887-89a27070c085/blocket.js',
  dba: ''
};
