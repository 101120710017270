import { debounce } from 'throttle-debounce';

import { $waitForClient } from '@client/core/atoms/config';
import { addLifecycleEvent } from '@client/core/atoms/metrics';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import { loadRelevantDigitalPrebid } from '@client/core/relevantDigital/prebid';
import { getDebouncedSendMetricsFunction } from '@client/core/services/metrics';
import { Slot, UNLEASH_FEATURE_NAME } from '@schibsted-nmp/advertising-shared';

let pendingSlots: Slot[] = [];
// audience slots should not be loaded with prebid and needs loadBatchedAllSlots
let pendingAudienceSlots: Slot[] = [];

export function loadBatchedAllSlots(slots: Slot[]) {
  window.googletag.cmd.push(() => {
    window.googletag.pubads().refresh(slots);
  });
}

// Debounce function that accumulates slots and refreshes them after 200ms
const debounceLoadAd = debounce(
  200,
  () => {
    // copy pending slots to load
    const slotsToLoad = [...pendingSlots];
    const audienceSlotsToLoad = [...pendingAudienceSlots];
    // Reset pending slots
    pendingSlots = [];
    pendingAudienceSlots = [];
    // run prebid for regular slots
    if (isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamPrebid)) {
      loadRelevantDigitalPrebid(slotsToLoad);
    } else {
      // Load the batched slots
      loadBatchedAllSlots(slotsToLoad);
    }

    if (audienceSlotsToLoad.length > 0) {
      // Load the batched audience slots
      loadBatchedAllSlots(audienceSlotsToLoad);
    }

    addLifecycleEvent(
      `GAM Load batch of Ads for ${slotsToLoad.map((s) => s.getSlotElementId()).join(', ')}`
    );
    getDebouncedSendMetricsFunction()();
  },
  { atBegin: false }
);

export function debouncedLoadAstTags(slot: Slot) {
  if (slot.getSlotElementId().includes('audience')) {
    pendingAudienceSlots.push(slot);
  } else {
    pendingSlots.push(slot);
  }
  const waitForClient = $waitForClient.get();
  if (waitForClient) {
    $waitForClient.subscribe((wait) => {
      if (!wait) {
        debounceLoadAd();
      }
    });
  } else {
    debounceLoadAd();
  }
}

export function getDebouncedLoadAdFunction() {
  return debouncedLoadAstTags;
}
