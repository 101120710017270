// atomConstants.ts

import { $adnTargetingAtom } from '@client/core/atoms/adnTargeting';
import { setInitialPlacementStatusMetrics } from '@client/core/atoms/metrics';
import { getConsentOrSubscribe } from '@client/core/utils/consent';
import {
  AdServer,
  AfsConfig,
  AfsPlacement,
  ClientAdPlacement,
  devToolToggled,
  InitialState,
  setAdvtState
} from '@schibsted-nmp/advertising-shared';

import { $afsAtom } from './afs';
import {
  $config,
  $initialState,
  $ppidAtom,
  getEmptyOrPersistedPPID
} from './config';
import { $gamTargetingAtom } from './gamTargeting';
import {
  $placementsMap,
  $statusSequence,
  addStatusToSequence,
  getPlacementList,
  listenPlacementKeysForValueChange
} from './placements';
import { $unleashFeaturesAtom } from './unleashFeatures';
import { $skyscrapersPositionAtom } from './skyscrapersPositionAtom';

export enum AtomChannels {
  Podlet = 'advertising-podlet'
}

export enum AtomTopics {
  PlacementsMap = 'placementsMap',
  PPID = 'ppid',
  Glimr = 'glimr',
  Cycle = 'cycle',
  GamTargeting = 'gamTargeting',
  AdnTargeting = 'adnTargeting',
  PrebidLoadedList = 'prebidLoadedList',
  Config = 'config',
  Afs = 'afs',
  PlacementStatusMetrics = 'placementStatusMetrics',
  UnleashFeatures = 'unleashFeatures',
  SearchFilters = 'searchFilters',
  InitialState = 'initialState',
  XandrPageOpts = 'xandrPageOpts',
  WaitForClient = 'waitForClient',
  PlacementMetricSequence = 'placementMetricSequence',
  AdStatusSequence = 'adStatusSequence',
  SignificantEvents = 'significantEvents'
}

export const setInitialGlobalAtoms = (initialState: InitialState) => {
  $config.set(initialState?.config || {});
  $ppidAtom.set(getEmptyOrPersistedPPID());
  $gamTargetingAtom.set(initialState?.config?.adServer?.gam?.targeting || []);
  $adnTargetingAtom.set(initialState?.config?.adServer?.adn?.kv || []);

  getConsentOrSubscribe(async (permissionValue) => {
    const allowedAdservers =
      initialState?.config?.brand === 'dba'
        ? permissionValue === '1'
          ? [AdServer.GAM, AdServer.AFS]
          : [AdServer.Adn]
        : [AdServer.GAM, AdServer.AFS, AdServer.Adn];

    const allowedPlacements =
      initialState?.config?.placements.filter((placement) =>
        allowedAdservers.includes(placement.adServer.type)
      ) || [];

    const placementsMap: Record<string, ClientAdPlacement> =
      allowedPlacements.reduce<Record<string, ClientAdPlacement>>(
        (map, placement) => {
          map[`${placement.placementId}`] = {
            ...placement,
            status: 'pending',
            creativeType: '',
            statusSequence: []
          } as unknown as ClientAdPlacement;
          return map;
        },
        {} as Record<string, ClientAdPlacement>
      );

    if (devToolToggled() || initialState?.env === 'local') {
      allowedPlacements.forEach((placement) => {
        listenPlacementKeysForValueChange(
          placement.placementId,
          'status',
          (status, oldStatus) => {
            if (status !== oldStatus) {
              addStatusToSequence(placement.placementId, status);
            }
          }
        );
      });
    }
    $placementsMap.set(placementsMap);
  });

  $afsAtom.set({
    config: initialState?.config?.adServer.afs || ({} as AfsConfig),
    placements: initialState?.config?.placements.filter(
      (placement) => placement.adServer.type === AdServer.AFS
    ) as AfsPlacement[]
  });

  setInitialPlacementStatusMetrics(getPlacementList());
  $unleashFeaturesAtom.set(initialState?.unleashFeatures || {});
  $initialState.set({
    env: initialState?.env,
    baseUrl: initialState?.baseUrl,
    logSessionId: initialState?.logSessionId
  });

  window.skyscrapersAtom = {
    get: () => $skyscrapersPositionAtom.get(),
    set: (value: 'sticky' | 'static') => $skyscrapersPositionAtom.set(value)
  };
};

setAdvtState('state', () => ({
  initialState: $initialState.get(),
  config: $config.get(),
  ppid: $ppidAtom.get(),
  placements: $placementsMap.get(),
  afs: $afsAtom.get(),
  unleashFeatures: $unleashFeaturesAtom.get(),
  gamTargeting: $gamTargetingAtom.get(),
  adnTargeting: $adnTargetingAtom.get(),
  statusSequence: $statusSequence.get()
}));
